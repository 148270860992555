/** @format */

import React, { useEffect, useRef } from "react";
import "./style.scss";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useState } from "react";
import LoadingIcon from "../loading-icon";
import { useDispatch, useSelector } from "react-redux";
import { reqSetPage } from "../../reduxs/home/action";
import { reqGetUserProfile } from "../../reduxs/user/action";
import { USER_GROUP } from "../../constants/master-data";
import socket from "../../helper/socket";
import icBayHarborTower from "../../assets/images/icBayHarborTower.png";
import Profile from "./profile";
import { useHistory } from "react-router-dom";
import { reqGetListAgents } from "../../reduxs/cms/action";

const Loading = (props) => {
  const { isLoading, isPresentation } = props;
  const [isShowVideo, setShowVideo] = useState(false);
  const authUser = useSelector((state) => state.user.data);
  const agents = useSelector((state) => state.cms.agents);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const customer = useSelector((state) => state.user.customer);
  const [isShowProfile, setShowProfile] = useState(false);
  const history = useHistory();

  useEffect(() => {
    dispatch(reqGetUserProfile());
    dispatch(reqGetListAgents());
  }, []);

  useEffect(() => {
    if (authUser) {
      analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email,
      });
    }
  }, [authUser]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.START_SESSION) {
      onStartSession(content.data.userId);
    }
    if (content.action === ACTION_NAME.SKIP_SESSION_VIDEO) {
      startDiscover();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const startDiscover = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SKIP_SESSION_VIDEO);
    }
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    analytics.track("Agent Started Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientName: customer?.name,
      clientEmail: customer?.email,
      clientPhone: customer?.phone,
    });
  };

  const onExplore = () => {
    setShowProfile(true);
  };

  const onStartSession = (userId) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.START_SESSION, { userId });
    } else {
      history.push({
        search: `?customer=${userId}`,
      });
    }
    // dispatch(reqSetIsShowReplayVideo(true));
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    // setShowVideo(true);
    setShowProfile(false);
    // if (videoRef.current) {
    //   if (isPresentation) {
    //     videoRef.current.muted = true;
    //   }
    //   videoRef.current?.play();
    // }
  };

  const renderExploreButton = () => {
    if (!authUser) return;

    if (
      [USER_GROUP.INVESTOR, USER_GROUP.CONSULTANT].includes(
        authUser?.userGroup.name
      )
    ) {
      return (
        <button className="explore_button" onClick={() => startDiscover()}>
          EXPLORE THE TOWERS
          <img src="/icons/vector.svg" alt="explore-towers" />
        </button>
      );
    }

    if (authUser?.userGroup.name === USER_GROUP.ADMIN) {
      return (
        <button className="explore_button" onClick={() => onExplore()}>
          EXPLORE THE TOWERS
          <img src="/icons/vector.svg" alt="explore-towers" />
        </button>
      );
    }
  };

  const handleCancel = () => {
    setShowProfile(false);
  };
  //disable-btn

  return (
    <div id="loading-screen">
      <div id="loading-cube-wrapper" />
      <div className={`wrap-intro ${isShowVideo ? "hide" : "show"}`}>
        {!isShowProfile ? (
          <div
          >
            <video
              resizeMode='cover'
              autoPlay
              muted
              loop
              preload="none"
              id="intro-video"
              style={{ backgroundImage: `url("./uploads/images/poster.jpg")` }}
            >
              <source src="./uploads/media/intro/introduce.webm" type="video/webm" />
            </video>
            <div className="intro-content">
              <div />
              <img src={icBayHarborTower} alt="bay-harbor-tower" />
              <div className="loading-button">
                <div className="loading-icon">
                  {!!isLoading && <LoadingIcon />}
                </div>
                <div
                  className={`wrap-button ${isLoading ? "disable-btn" : ""}`}
                >
                  {renderExploreButton()}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Profile
            startDiscover={onStartSession}
            handleCancel={handleCancel}
            agents={agents}
            isPresentation={isPresentation}
          />
        )}
      </div>
      {/* <div className={`wrap-slide-loading ${isShowVideo ? "show" : "hide"}`}>
        {
          <>
            <video
              ref={videoRef}
              autoPlay={false}
              onEnded={() => startDiscover()}
              preload="auto"
              id="intro-video-2"
              muted={isPresentation}
            >
              <source src="/uploads/videos/2269289e.mp4" type="video/mp4" />
            </video>
            <img className="intro" src="/uploads/images/intro.png" alt="intro" />
            <img className="play" src="/uploads/images/play.svg" alt="play" />
            <div
              onClick={() => startDiscover()}
              className="btn-skip-video btn btn-light"
            >
              <span>{t("common.skipVideo")}</span>
              <img src="/uploads/icons/arrow-right.svg" alt="arrow" />
            </div>
          </>
        }
      </div> */}
      {/* <div style={{ position: "relative" }}>
        <UserTypeModal />
      </div> */}
      {/* <ListUserSelectModal startDiscover={onStartSession} />
      <CreateUserProfileModal startDiscover={onStartSession} /> */}
      {/* {!isShowVideo && <div className="disclaimer"><span>{t('disclaimer')}</span></div>} */}
    </div>
  );
};

export default Loading;
