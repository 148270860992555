import React, { useEffect, useState } from "react";
import "./forgot-password.scss";
import authApi from "../../apis/api/auth";
import { useHistory } from "react-router-dom";
import ROUTE_PATH from "../../routers/path";
import { toast } from "react-toastify"
import logo from "../../assets/images/logo.svg";
import classNames from "classnames";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (email) {
      setErrorMessage("")
    }
  }, [email]);

  const handleForgotPassword = async () => {
    const data = {
      email: email,
    };

    const res = await authApi.forgotPassword(data);

    if (res?.data) {
      setErrorMessage("")
      toast.success("Reset password email sent")
    } else {
      setErrorMessage("Email not found");
    }
  };

  const handleLogin = () => {
    history.push(ROUTE_PATH.HODLDING_PAGE);
  }

  return (
    <div className="wrap-holding-page h-100">
      <div className={`modal-form`}>
        <div className="logo-container">
          <img src={logo} className="logo" />
        </div>
        <div className="modal-form__body">
          <input
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className={classNames("form-input", {
              spacing: !errorMessage,
            })}
            id="email"
            placeholder="Email"
          />
          {errorMessage && (
            <p id="passwordHelp" className="text-danger spacing mt-2">
              {errorMessage}
            </p>
          )}
          <button
            type="submit"
            onClick={handleForgotPassword}
            className="btn-holding-login spacing"
          >
            <span className="txt-login">Enter</span>
          </button>
          <span className="link-btn" onClick={handleLogin}>
            Login
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
