import React, { useEffect, useState } from "react";
import "./index.scss";
import jwtAuth from "../../apis/utils/jwtAuth";
import authApi from "../../apis/api/auth";
import { useHistory } from "react-router-dom";
import ROUTE_PATH from "../../routers/path";
import logo from "../../assets/images/logo.svg";
import classNames from "classnames";

const HoldingPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (email || password) {
      setErrorMessage("")
    }
  }, [email, password]);

  const handleForgotPassword = () => {
    history.push(ROUTE_PATH.FORGOT_PASSWORD);
  };

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      history.push("/");
    } else {
      setErrorMessage("Email not found or password does not match");
    }
  };

  return (
    <div className="wrap-holding-page h-100">
      <div className={`modal-form`}>
        <div className="logo-container">
          <img src={logo} className="logo" />
        </div>
        <div className="modal-form__body">
          <input
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="form-input spacing"
            placeholder={"Email"}
          />
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            className={classNames("form-input", {
              spacing: !errorMessage,
            })}
            id="password"
            placeholder="Password"
          />
          {errorMessage && (
            <p id="passwordHelp" className="text-danger spacing mt-2">
              {errorMessage}
            </p>
          )}
          <button
            type="submit"
            onClick={handleLogin}
            className="btn-holding-login spacing"
          >
            <span className="txt-login">Enter</span>
          </button>
          <span className="link-btn" onClick={handleForgotPassword}>
            Reset password
          </span>
        </div>
      </div>
    </div>
  );
};

export default HoldingPage;
